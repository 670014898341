@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.kdashheader {
  font-weight: 600;
  font-size: 2.1rem;
  word-spacing: 1.2px;
  line-height: normal;
  letter-spacing: normal;
  color: #001833;
  padding-bottom: 0.1rem;
  padding-top: 2rem;
  padding-top: 2rem;
}
.kdashheaderlight {
  font-weight: 400;
  font-size: 32px;
  letter-spacing: normal;
  color: #001833;
}
.chartss text {
  font-size: 10px;
}
.jcenter1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}
.additional {
  margin-bottom: 1rem;
}

.kdash1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #444444;
  margin-bottom: 2rem;
}
.kli6 {
  justify-content: center;
  margin-top: 2rem;
}
.hgjs {
  background: #F2461B;
  color: #fff;
  border: none;
  width: 100%;
}

.hgjs:hover {
  background: #F2461B;
  color: #fff;
  border: none;
}
.kfirstlogo {
  width: 80%;
  border-radius: 6px;
  min-width: 280px;
  margin-left: 2rem;
}
.kdash1 span {
  font-weight: 500;
  color: #001833;
}
.kdasharea {
  background: #f7f7f7;
  display: flex;
  margin-bottom: 1.2rem;
  border-radius: 6px;
  justify-content: space-around;
  flex-direction: row;
}
.kprofile {
  font-size: 15px;
  color: rgb(153, 152, 152);
}
.kprofile2 {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 62px;
  color: #001833;
}
.kprofile3 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 62px;
  color: #001833;
}
.kprofilewrap {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  justify-content: center;
}
.resultsec2 {
  display: flex;
  margin-top: 1.3rem;
}
.resultsec22 {
  background-color: #001833;
  padding: 1rem;
  border-radius: 6px;
  max-height: 228px;
}
.reskwrap13 {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: space-around;
  width: 45%;
}
.resultsec3 {
  display: flex;
  margin-top: 1.2rem;
  justify-content: space-between;
  background-color: #f7f7f7;
  border-radius: 6px;
  margin-bottom: 2rem;
}
.resultsec13 {
  display: flex;
  margin-top: 1.2rem;
  justify-content: space-between;
  background-color: rgb(236, 236, 236);
  border-radius: 6px;
  margin-bottom: 2rem;
  padding: 1rem;
  padding-right: 4rem;
}
.csfitscore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  margin-left: 1rem;
  background-color: rgb(236, 236, 236);
  border-radius: 6px;
}
.secondlogo {
  border-radius: 6px;
}
@media (max-width: 400px) {
  .secondlogo {
    max-width: 100%;
    height: auto;
  }
  .fjss {
    padding-right: 0px;
  }
  .kdasharea {
    flex-wrap: wrap;
  }
  .kfirstlogo {
    width: 100%;
    height: 180px;
  }
}
@media (min-width: 780px) {
  .lkl {
    padding-left: 0rem;
  }
}
.csfitscore1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #001833;
  padding-bottom: 0.9rem;
  /* padding-top: 0.9rem; */
}

.csbody {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  word-spacing: 1.5px;
  padding-right: 2rem;
  color: #001833;
  padding-bottom: 0.5rem;
}
.reskwrap {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
}
.rdrap {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 0px;
  justify-content: center;
}
.resultt {
  padding: 1rem 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.fullresult {
  background: #F2461B;
  padding: 0.7rem 1.3rem;
  border-radius: 4px;
  display: inline;
  color: #fff;
  cursor: pointer;
}
.kitemwrapper {
  width: 250px;
  height: 200px;
  background: rgb(236, 236, 236);
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 1rem 0.8rem;
  transition: transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}
.kitemwrapper:hover {
  transform: scale(1.15);
  box-shadow: 5px 10px 18px #888888;
}
.v1strength {
  color: rgb(138, 136, 136);
  text-align: center;
  font-size: 14px;
  padding-top: 0.8rem;
}
.kigennidisabled {
  margin-top: 2rem;
}
.mncb {
  width: 79%;
}
.kz2a {
  border-radius: 6px;
  background-color: #001833;
  color: #fff;
  width: 100%;
  display: flex;
  height: 48px;
  align-items: center;
  padding-left: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.kz2 {
  border-radius: 6px;
  background-color: #001833;
  color: #fff;
  width: 100%;
  font-weight: 600;
  display: flex;
  height: 48px;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.contkflex {
  width: 49%;
}
.kz1 {
  display: flex;
  justify-content: space-between;
}
.kl3 {
  width: 15px;
  margin-right: 1.5rem;
}
.kz12 {
  background: #f8f8f8;
  border-radius: 8px;
}
.grapwrap {
  text-align: left;
  padding: 1rem 2rem;
  list-style: inside;
  border-radius: 8px;
}
.grapwrap li {
  padding-top: 1rem;
}
.competence {
  margin-top: 3rem;
  margin-bottom: 1.3rem;
  font-weight: 700;
  color: #001833;
  font-size: 18px;
}
.competence1 {
  margin-bottom: 3rem;
  font-weight: 700;
  color: #001833;
  font-size: 16px;
}
.ikls {
  font-size: 15px;
  line-height: 35px;
  text-align: justify;
  font-weight: 900;
  letter-spacing: -0.3px;
  color: #001833;
}

.otherinfo {
  font-size: 16px;
}
.stbly1 {
  font-weight: 700;
  padding-bottom: 1rem;
}
.stbly {
  margin-bottom: 3rem;
}
.nlodd {
  margin-top: 5rem;
}
.tipswrapper {
  background: #001833;
  word-spacing: 1.5px;
  line-height: 1.8;
  color: #fff;
  font-weight: 100;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .kdasharea {
    background: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.2rem;
    border-radius: 6px;
    justify-content: space-around;
    flex-direction: column-reverse;
  }
  .ncb {
    width: 100%;
    text-align: center;
  }
  .firstoffour {
    max-width: 150px;
  }
  .councellors_response1 {
    margin-left: 2rem !important;
  }
  .siddle {
    padding-top: 1rem !important;
    padding-right: 1rem !important;
  }
  .kfirstlogo {
    min-width: 211px;
  }
  .payheader {
    font-size: 20px;
  }
  .resultsec2 {
    background: #ececec;
    flex-wrap: wrap;
    padding-top: 1rem;
  }
  .resultsec3 {
    flex-wrap: wrap;
    border-radius: 14px;
  }
  .noticee {
    display: none;
  }
  .reskwrap {
    padding-left: 1rem;
  }
  .stbly {
    padding-left: 1rem;
  }
  .secondlogo {
    border-radius: 6px;
    width: 98%;
  }
  
  .csfitscore {
    padding-left: 0rem;
  }
  .mncb {
    width: 100%;
  }
  .kz1 {
    display: block;
  }
  .contkflex {
    width: 100%;
  }
  .resultsec13 {
    flex-wrap: wrap;
  }
  .reskwrap13 {
    width: 100%;
  }
  .kprofile {
    padding-left: 1rem;
    padding-top: 1rem;
  }
  .kprofile2 {
    padding-left: 1rem;
    font-size: 28px;
    line-height: 30px;
  }
  .resultsec22 {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .kprofilewrap {
    padding-left: 27vw;
    padding-top: 2rem;
  }
}

.vbnc1 {
  font-size: 16px;
  font-weight: 400;
  color: #001833;
  letter-spacing: normal;
}
@media (max-width: 500px) {
  .contkflex {
    box-shadow: 1.5px 6px 10px rgba(24, 33, 77, 0.25);
    border-radius: 10px;
  }
  .infoforimage {
    display: none !important;
  }
  .ttp1 {
    font-size: 14px;
  }
  .resultsec22 {
    margin: 0px;
  }
  .kl3 {
    width: 27px;
  }
  .grapwrap {
    text-align: left;
    padding: 1rem 1rem;
    line-height: 1.5;
    word-spacing: 4px;
  }
  .resultsec2 {
    background: #001833;
  }
  .career221 {
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    text-align: justify;
    word-spacing: 4px;
  }
  .competence1 {
    color: #fff;
  }
  .cptext {
    line-height: 1.8;
    word-spacing: 4px;
  }
  .resultsec2 {
    justify-content: center;
    border-radius: 8px;
  }
  .csfitscore {
    background: #001833;
    color: #fff;
  }
  .csfitscore1 {
    display: none;
  }
  .reskwrap {
    padding-left: 0.5rem;
    padding-top: 2.3rem;
    padding-right: 0.2rem;
    padding-bottom: 2rem;
  }
  .secondlogo {
    display: none;
  }
  .careerpersonalityheader {
    color: #001833;
    font-size: 22px;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 0.4rem;
    text-align: center;
  }
  .resultsec3 {
    justify-content: center;
  }
  .csfitscore2 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 0.4rem;
    width: 100%;
    text-align: center;
  }
  .tipstext {
    word-spacing: 4.2px;
    font-weight: 300;
    font-size: 12px;
    line-height: 26px;
    /* or 236% */
  }
  .tttpt {
    padding-bottom: 1rem;
  }
  .csbody {
    color: #fff;
    padding-right: 0.3rem;
    font-weight: 200;
    font-size: 14px;
    padding-bottom: 2rem;
    word-spacing: 4.2px;
    line-height: 26px;
    opacity: 0.8;
  }
  .juki {
    display: block;
    text-align: center;
    font-size: 20px;
  }
  .tipswrapper {
    padding: 1rem;
  }
  .stbly1 {
    text-align: center;
    font-size: 17px;
  }
  .noticeee {
    display: flex;
    justify-content: center;
  }
  .noticeee1 {
    width: 27px;
  }
  .underlinee {
    border: 1px solid;
    width: 90px;
    margin: auto;
  }
  .centerpurple,
  .centerblue {
    margin-right: 0px;
  }
}
@media (min-width: 501px) {
  .juki {
    display: none;
  }
  .psdd1 {
    padding-left: 0px;
    width: 90% !important;
  }
  .noticeee {
    display: none;
  }
  .underlinee {
    display: none;
  }
  .csfitscore2 {
    display: none;
  }
  .liuii {
    color: #fff;
  }
  .careerpersonalityheader {
    display: none;
  }
}

@media (max-width: 500px) {
  .vbnc1 {
    padding-bottom: 0.8rem;
  }
}
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
.payheader {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  word-spacing: 1.6px;
  line-height: 62px;
  text-align: center;
  color: #001833ec;
  margin-bottom: 3rem;
}

.slcplan1 {
  color: #fff;
  background: transparent;
  display: block;
  padding: 0.4rem 1.5rem;
  margin-top: 0.5rem;
  width: 100%;
  text-align: center;
  color: #001833;
  border-radius: 1rem;
  border: 1.4px solid #F2461B;
}

.lmi1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.6rem;
  padding-bottom: -0.9rem;
}

.lmi2 {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.amut {
  color: #001833;
  font-weight: 700;
  font-size: 2rem;
  padding-right: 0.4rem;
}
.amurt {
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}
.amut1 {
  width: 30%;
  line-height: 1;
  font-size: 13px;
}
.centerr {
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 4rem;
}

.centerr1 {
  border-top: 4px solid #ffad08;
  justify-content: center;
  background: #f8f8f8;
  margin-right: 3rem;
  margin-top: 1rem;
  padding-left: 0px;
  padding-right: 0px;
  background: #fefefe;
  border-radius: 8px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  box-shadow: 4px 12px 30px rgba(24, 33, 77, 0.25);
}
.centerr2 {
  justify-content: center;
  background: #fff;
  padding: 2rem 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}
.checkcircle {
  width: 14px;
  margin-right: 0.3rem;
}
.prems {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  /* font-family: "Ubuntu" */;
  color: #001833;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1.4rem;
}
.premq {
  font-size: 14px;
}
.premq1 {
  font-size: 14px;
  padding-bottom: 1.3rem;
}
.comps1 {
  font-size: 13px;
  font-weight: 400;
  padding-top: 1rem;
}
.comps2 {
  display: flex;
  font-size: 13px;
  font-weight: 500;
  padding-top: 0.1rem;
  line-height: 2.4;
  padding-left: 1rem;
  padding-right: 0.6rem;
}
.slcplan {
  cursor: pointer;
}
.pds {
  width: 100%;
}

.resultsec31 {
  display: flex;
  margin-top: 1.2rem;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
.ttp {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}
.ttp1 {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  padding-left: 1rem;
}
.btmwrap {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2rem;
}
@media (max-width: 400px) {
  .resultt {
    padding: 0.1rem;
  }
}
.retaketest {
  background: #F2461B;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #F2461B;
  margin-right: 1rem;
  min-width: 159px;
  cursor: pointer;
}
.retaketest:hover {
  background: #F2461B;
  color: #fff;
  border: 1px solid #F2461B;
}
.retaketest1 {
  background: #F2461B;
  color: white;
}
.fkexx {
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;
}

.percentoff {
  font-size: 14px;
  font-weight: 400;
  background: #ffad08;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #ececec;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  justify-content: center;
}
.modal-content {
  border-radius: 0.6rem !important;
}
.feedbackques {
  font-size: 13px;
  padding-bottom: 0.2rem;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
.feedbackheader {
  font-size: 14px;
}
.modal-content {
  padding: 1rem;
}
@media (min-width: 576px) {
  .modal-sm {
    max-width: 429px;
  }
}

textarea.form-control {
  height: 93px;
  padding: 10px 2rem;
}
::placeholder {
  font-size: 14px;
}
.oius {
  font-size: 14px;
}
.selecss {
  margin-top: 0.1rem;
}
.flexsa {
  display: flex;
}
.copylink {
  color: #3965ff;
  font-size: 13px;
  /* display: inline-block; */
  background: rgba(57, 100, 255, 0.315);
  margin-left: 0.4rem;
  /* display: flex; */
  align-items: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  width: 215px;
  border: none;
  border-radius: 0.5rem;
}
.losj {
  margin-bottom: 0.5rem;
  font-size: 13px;
}
.selectopt {
  border-color: #fff;
}
.getlink {
  width: 36px;
  margin-right: 0.2rem;
}
.bvcc {
  display: flex;
  padding-bottom: 0.4rem;
}
.feedbackques1 {
  font-size: 12px;
}
.alert-success {
  left: 22%;
  text-align: center;
  margin: auto;
}
.react-calendar {
  width: inherit !important;
  border: 0px solid #a0a096 !important;
}
.scheduleheader {
  text-align: center;
  font-size: 1.7em;
  color: #001833;
  padding-bottom: 1rem;
}
.react-calendar__month-view__days__day--weekend {
  color: #bbbbbb7e !important;
  /* background: #fafbfd !important; */
}
.react-calendar__tile
  .react-calendar__month-view__days__day
  .react-calendar__month-view__days__day--weekend {
  color: #ececec !important;
}
.react-calendar__tile--now {
  background: none !important;
  color: #a0a096 !important;
}
abbr[data-original-title],
abbr[title] {
  color: #a0a096 !important;
}
.fw2 {
  margin-top: 1rem;
}
.whatdou {
  border: 0px transparent;
  background: #f5f7f9 !important;
  border-radius: 8px;
  padding-left: 2rem;
}

.react-calendar button {
  color: #3a405b !important;
  font-size: 12px !important;
}
.react-calendar__tile--active:enabled:hover {
  color: #6e6e6e !important;
  width: none !important;
}
.react-calendar__tile--active:enabled:focus {
  color: #fff !important;
  width: none !important;
}
.activeDate {
  color: #3a405b !important;
  text-align: center;
  border: 1px solid rgba(226, 223, 223, 0.596);
  border-bottom: 0.1px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  border-left: 0px;
  border-right: 0px;
  height: 35px;
}
.availabledate {
  text-align: center;
  color: #a5a5a5;
  border: 1px solid rgba(226, 223, 223, 0.596);
  border-bottom: 0.1px;
  display: flex;
  justify-content: space-evenly;
  cursor: not-allowed;
  align-items: center;
  border-left: 0px;
  border-right: 0px;
  height: 35px;
}
.availabledatewrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.bordenone {
  border-bottom: none;
}
.shex {
  justify-content: space-between;
}
.booksession {
  background: #F2461B;
  border-radius: 8px;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  font-size: 14px;
  margin-top: 1.6rem !important;
  cursor: pointer;
  height: fit-content;
}

.jcenter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.notop {
  border-top: 0px solid;
}
.enter11 {
  font-size: 14px;
  color: #6e6e6e;
  padding-left: 0.4rem;
  padding-bottom: 0.5rem;
  position: relative;
  top: 1.5rem;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: none;
  color: #3965ff;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: transparent !important;
  color: #3965ff;
}

.react-calendar__tile:disabled {
  background: #fafbfd !important;
  cursor: not-allowed;
  color: #cac6c6 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #3965ff !important;
}

.leavereveiw {
  padding-bottom: 1rem;
  cursor: pointer;
}
.btnws {
  font-size: 12px;
  padding: 0.4rem 0.6rem;
  border-radius: 1rem;
  min-width: 80px;
}

@media (max-width: 600px) {
  .payheader {
    font-size: 20px;
  }
  .centerr1 {
    margin: auto;
    margin-bottom: 4rem;
  }
}

.write {
  position: relative;
  top: 1.9rem;
  width: 13px;
  left: 0.5rem;
}
@media (max-width: 500px) {
  .calwrapper {
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
  .fw2 {
    margin-top: 0rem;
  }
  .leastbusexp {
    text-align: center;
  }
}
.calwrapper {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  padding-top: 0.5rem;
  margin-right: 1rem;
}
.leastbusexp {
  padding-top: 4rem;
  color: #001833;
  font-size: 22px;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 0.4rem;
  text-align: left;
}
.jksk {
  margin-top: 3rem;
}

.fba {
  margin-bottom: 1rem;
}
.duration {
  /* Dark */
  color: #a5a5a5;
}

.planinsight {
  font-weight: bold;
  font-size: 22px;
  line-height: 10px;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  /* Dark Blue */
  color: #001833;
}
.oneoff {
  font-size: 14px;
  line-height: 30px;
  color: #a5a5a5;
  text-align: center;
  padding-top: 0.5rem;
}
.traingle {
  width: 56px;
  color: #ffad08;
  background: #ffad08;
  display: block;
  z-index: 120;
  border: 2px solid;
  transform: rotate(45deg);
  position: relative;
  left: 14.56rem;
  top: 1rem;
}
.slcplan {
  color: #fff;
  background: #F2461B;
  margin: auto;
  display: block;
  padding: 0.4rem 1.5rem;
  margin-top: 0.5rem;
  width: 59%;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 4rem;
  min-width: 135px;
}
.centerpurple {
  border-top: 4px solid #F2461B;
  justify-content: center;
  background: #f8f8f8;
  margin-right: 3rem;
  margin-top: 1rem;
  padding-left: 0px;
  padding-right: 0px;
  background: #fefefe;
  border-radius: 8px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  box-shadow: 4px 12px 30px rgba(24, 33, 77, 0.25);
}
.percentoffpurple {
  font-size: 14px;
  font-weight: 400;
  background: #F2461B;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #ececec;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  justify-content: center;
}
.percentoffblue {
  font-size: 14px;
  font-weight: 400;
  background: #3965ff;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #ececec;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  justify-content: center;
}
.centerblue {
  border-top: 4px solid #3965ff;
  justify-content: center;
  background: #f8f8f8;
  margin-right: 3rem;
  margin-top: 1rem;
  padding-left: 0px;
  padding-right: 0px;
  background: #fefefe;
  border-radius: 8px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  box-shadow: 4px 12px 30px rgba(24, 33, 77, 0.25);
}
@media (max-width: 600px) {
  .centerpurple {
    margin-bottom: 1rem;
  }
  .centerpurple,
  .centerblue {
    margin-right: 0px;
  }
  .centerr1 {
    margin-bottom: 2rem;
  }
}
.coonfused {
  font-size: 26px;
  line-height: 41px;
  text-align: center;
  color: #001833;
}
.retaketest2 {
  background: #fff;
  border-radius: 6px;
  color: #F2461B;
  border: 1px solid #F2461B;
  margin-right: 1rem;
  border: 1px solid #F2461B;
  border-color: #F2461B !important;
  width: 168px;
  min-width: 159px;
}
.retaketest2:hover {
  background: #fff;
  border-radius: 6px;
  color: #F2461B;
  border: 1px solid #F2461B;
  border-color: #F2461B !important;
}
.pool {
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  color: #001833;
}
@media (max-width: 500px) {
  .check11 {
    display: contents;
  }
  .resultsec13 {
    display: flex;
    margin-top: 1.2rem;
    justify-content: space-between;
    background-color: rgb(236, 236, 236);
    border-radius: 6px;
    margin-bottom: 2rem;
    /* padding: 1rem; */
    padding-right: 0rem;
    text-align: center;
  }
  .retaketest2 {
    margin-top: 0.4rem;
  }
}
.siddle {
  background: #001833;
  min-height: 80vw;
  padding: 0rem;
  color: #fff;
  border-top: 4px solid #F2461B;
  padding-top: 1rem;
}
.chage {
  background: #1bb978;
}
.chag11 {
  border-top: 4px solid #1bb978;
}
.siddle12 {
  background: #F2461B;
  height: 7px;
}
.imgCart {
  margin-top: 5rem;
  margin: auto;
  width: 60px;
  /* margin-left: 4.5rem; */
}
.navdash {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}
.prm {
  padding-left: 0px;
  padding-right: 0px;
}

.prm11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
}
.prm111 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 14px;
}
.navdash {
  display: flex;
  justify-content: space-between;
}
.overview {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.ovf {
  color: #001833;
  font-weight: 500;
  font-size: 16px;
  padding-left: 2rem;
}
.avatar11 {
  margin-left: 0.4rem;
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.navitem1 {
  color: #fff;
  padding-top: 4rem;
  padding-left: 0.1rem;
  font-size: 12px;
  line-height: 4.4;
}

.avar {
  width: 80px;
  max-width: 80px;
  object-fit: contain;
}
.kisls {
  padding-left: 3rem;
  padding-top: 3rem;
}
.ksname {
  font-weight: 800;
}
.fjss {
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
  flex-wrap: wrap;
}
.proff2 {
  font-weight: bold;
  font-size: 20px !important;
  color: #001833;
  line-height: 1;
}
.smalls {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #001833;
  letter-spacing: normal;
  max-width: 80px;
  max-height: 80px;
  overflow: hidden;
  border-radius: 6px;
}
.idds {
  font-size: 28px;
  letter-spacing: normal;
}
.sowws {
  width: 226px;
  line-height: 29px;
}
.psdd {
  padding-left: 0px;
}

.caerr {
  font-size: 28px;
}
.insightss {
  color: #F2461B;
  text-align: right;
  font-weight: 700;
  cursor: pointer;
  padding-bottom: 2rem;
}
.sideimage {
  margin-right: 0.4rem;
  width: 14px;
  max-width: 14px;
  min-width: 14px;
}
.dlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.whiteline {
  background: #fff !important;
}
.navitemnone {
  display: none;
}
.siddlenone {
  min-height: auto;
}
.texsss1 {
  text-align: right;
}
@media (max-width: 400px) {
  .fjss {
    padding-right: 0px;
    line-height: 1.6;
    padding: 2rem 0rem;
  }
  .sendtcont {
    margin-top: 1rem;
    margin-right: 2rem;
    width: 100% !important;
    margin-left: 0rem !important;
  }
  .liee {
    display: block !important;
  }
  .kisls {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .texsss1 {
    text-align: left;
  }
  .kitemwrapper {
    width: 100% !important;
  }
}
.liee {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
}
.briefcase {
  width: 16px;
  margin-bottom: 0.5px;
  margin-right: 0.5rem;
}
.lds1 {
  border: 0px solid;
}
.lds1:hover {
  border: 0px solid;
  background: transparent;
}
.iussP {
  text-align: right;
}
.lds1:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: none !important;
  color: #F2461B;
}
.whatdoudo {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 1rem;
  letter-spacing: normal;
}
.liee > div {
  display: flex;
  align-items: center;
}

.uidd11 {
  padding-top: 0px;
}
textarea.form-control.jobr {
  padding-left: 0.7rem;
}
.check11 {
  text-align: right !important;
}
textarea.form-control.jobr {
  border: 0.1px solid inherit !important;
}
.gbn {
  color: #a5a5a5;
  line-height: 41px;
  padding-left: 1rem;
  cursor: pointer;
  margin-top: 1px;
}
.gbn > a {
  cursor: pointer;
}
.gbn:hover {
  background: rgba(255, 255, 255, 0.027);
}
.activegb {
  margin-top: 1px;
  line-height: 41px;
  border-left: 1px solid white;
  padding-left: 1rem;
  background: rgba(255, 255, 255, 0.027);
}

.divide_thro {
  height: 0.1px;
  background: #d4d3d359;
  margin-bottom: 0.4rem;
  margin-top: 0.3rem;
}
.jobr {
  height: 93px;
  font-size: 14px;
}
.rowla {
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.offpadd1 {
  padding-bottom: 0rem;
  padding-top: 1rem;
}
.smalls22 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #001833;
  letter-spacing: normal;
  overflow: hidden;
  border-radius: 6px;
}

.ksk1 {
  background: #F2461B;
  color: #fff;
  text-align: right;
  font-weight: 700;
  display: inline;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  cursor: pointer;
}
.kskthin {
  background: #F2461B;
  color: #fff;
  text-align: right;
  font-weight: 400;
  display: inline;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  cursor: pointer;
}
.duis {
  padding-bottom: 0.4rem;
  padding-right: 0.1rem;
}
.line2a {
  width: 28px;
  background: #F2461B;
  height: 3px;
  margin-top: 5px;
}
.hnav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.3rem 0.8rem;
}
@media (min-width: 801px) {
  .hnav {
    display: none;
  }
}
@media (max-width: 800px) {
  .imgCart {
    margin-top: 5rem;
    margin: auto;
    width: 30px;
    margin-left: 0.5rem !important;
  }
  .kisls {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    overflow-x: hidden !important;
    padding-bottom: 2rem;
  }
  .hamburger {
    background: none !important;
    padding: 1.1rem;
  }
  .hnav {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  }
  .siddle {
    display: none;
  }
  .siddlemobile {
    background: #001833;
    min-height: 80vw;
    padding: 0rem;
    color: #fff;
    border-top: 4px solid #F2461B;
    padding-top: 1rem;
  }
  .whatdoudo {
    padding-top: 1rem;
  }
  .navdash {
    display: none;
  }
}
.what12 {
  display: flex;
  align-items: center;
}
.plusnew {
  font-size: 19px;
  font-weight: 600;
  color: #3965ff;
  padding-left: 2rem;
  display: flex;
  line-height: 1;
  cursor: pointer;
}

.jssss {
  padding-left: 0px;
}
.siddlemobile {
  background: #001833;
  min-height: 80vw;
  padding: 0rem;
  color: #fff;
  border-top: 4px solid #F2461B;
  padding-top: 1rem;
}
.subhyt {
  height: 50px !important;
}
.plusnew1 {
  font-size: 12px;
  background: white;
  display: inline-block;
  font-weight: 500;
  position: relative;
  top: 0.75rem;
  left: 0.7rem;
  color: #001833;
  padding: 0.2rem;
  cursor: pointer;
}
.plusnew2 {
  font-size: 12px;
  background: white;
  display: inline-block;
  font-weight: 500;
  color: #001833;
  padding: 0.2rem;
  cursor: pointer;
}
.offpad {
  padding-bottom: 0px;
}
.qflex {
  display: flex;
  margin-top: 2.3rem;
}
.ll122 {
  position: absolute;
  display: flex;
  margin-left: 5rem;
  height: 33px;
  align-items: center;
  top: 2px;
  flex-wrap: wrap;
}
.skills {
  background: #3965ff;
  border-radius: 40px;
  color: #fff;
  padding: 0.4rem 1rem;
  margin-left: 0.6rem;
}
.possqq {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.lass {
  padding-bottom: 1rem;
}
.app11 {
  margin-top: 0.5rem;
}
.unbtm {
  padding-bottom: 0.1px;
}
.print {
  color: #F2461B;
  border: 1px solid #F2461B;
  border-radius: 8px;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  font-weight: 700;
  margin-left: 1rem;
}
.savebtn {
  color: #fff;
  background: #F2461B;
  border-radius: 8px;
  border: 1px solid #F2461B;
  padding: 0.4rem 0.9rem;
  display: inline-block;
  font-weight: 700;
  cursor: pointer;
  width: 122px;
  text-align: center;
}
.printcv {
  padding-bottom: 1rem;
}
.experience {
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  color: #001833;
}

.npps {
  padding-top: 0px;
}
.picic {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin-left: 1rem;
}
.floo {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  flex-wrap: wrap;
}
.serv11 {
  color: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
}

.worddd {
  display: flex;
  align-items: center;
  font-weight: 500;
  word-spacing: normal;
  letter-spacing: normal;
  font-size: 18px;
}

.Complain {
  display: flex;
  align-items: center;
  font-weight: 500;
  word-spacing: normal;
  letter-spacing: normal;
  font-size: 14px;
}
.subsbs {
  padding-top: 1rem;
}
.councellors_response {
  background: #fafafa;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  border-radius: 0px 60px 60px 60px;
  width: 90%;
  line-height: 1.7;
  padding: 1.5rem;
  padding-left: 1.9rem;
  color: #3a405b;
}
.youwrap {
  font-size: 14px;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 0.7rem;
}
.councwrap {
  font-size: 14px;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  text-align: right;
}

.you11 {
  background: #e4b8ff;
  border-radius: 8px;
  padding: 0.3rem 2rem;
}
.you11b {
  background: #b9f4bc;
  border-radius: 8px;
  padding: 0.3rem 2rem;
}
.councellors_response1 {
  background: #ebeeff;
  border-radius: 60px 60px 0px 60px;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  width: 90%;
  line-height: 1.7;
  padding: 1.5rem;
  padding-left: 1.9rem;
  color: #3a405b;
  margin-left: 6rem;
}

.youdate {
  padding: 1rem;
  color: #3965ff;
}
.youwss {
  padding-left: 0px;
}

.youwraprev {
  font-size: 14px;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: 400;
  text-align: right;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.7rem;
}
.sendtcont {
  margin-top: 1rem;
  margin-right: 2rem;
  width: 97%;
  margin-left: 1rem;
}
.sendmeess {
  background: #F2461B;
  color: #fff;
  /* margin-top: 1rem; */
  word-spacing: normal;
  letter-spacing: normal;
  margin-right: 1.4rem;
  font-size: 14px;
  text-align: right;
  font-weight: 200;
  display: inline;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  cursor: pointer;
}
.textrrr {
  text-align: right;
  padding-right: 10%;
}
.norec {
  text-align: center;
}
.norecommendations {
  width: 32%;
}
.udont {
  text-align: center;
  font-size: 15px;
  letter-spacing: normal;
  font-weight: 400;
}
.udont1 {
  text-align: center;
  font-size: 18px;
  letter-spacing: normal;
  font-weight: 500;
  line-height: 1.6;
}
.activeplac {
  font-size: 19px;
  letter-spacing: normal;
  line-height: normal;
}
.subtert {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  padding-top: 0.7rem;
}
.deleee {
  text-align: right;
}
.fa-trash {
  color: #F2461B;
  font-weight: 600;
  cursor: pointer;
}
.experience {
  border: 0.1px solid #a0a09656;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 1rem;
}
.plusnew12,
.subhyt12 {
  background-color: #eee;
  cursor: not-allowed;
}
.uii11 {
  font-size: 14px;
}
.bcash {
  font-size: 16px;
  letter-spacing: normal;
  color: #F2461B;
  line-height: normal;
}

.wrapc1 {
  display: flex;
  color: #979797;
  justify-content: space-between;
  font-size: 14px;
  font-size: 500;
  margin-top: 2rem;
  padding: 0.1rem 3rem;
  margin-bottom: 1rem;
}
.cname {
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  color: #18214d;
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 133px;
  max-width: 170px;
}
.cdate {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  align-items: flex-end;
  color: #18214d;
  justify-content: center;
}
.ctime {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  align-items: flex-end;
  color: #18214d;
  justify-content: center;
}
.wrapc2 {
  display: flex;
  color: #979797;
  justify-content: space-between;
  font-size: 13px;
  font-size: 300;
  margin-top: 0.2rem;
  align-items: center;
  background: #fefefe;
  box-shadow: 0px 2px 6px rgba(24, 33, 77, 0.25);
  border-radius: 8px;
  padding: 1.4rem 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  width: 93%;
}
.wrapline {
  width: 93%;
  border-top: 1px solid #bcbcbcb6;
  padding-bottom: 1.4rem;
  margin-top: 1.2rem;
}
.cname1,
.cdate1,
.ctime1,
.cdate,
.ctime {
  flex: 1;
  text-align: left;
}
.cstatus1,
.cstatus2 {
  width: 120px;
  text-align: left;
}
.cstatus2 {
  justify-content: center;
}
.cemail1 {
  display: flex;
  align-items: flex-end;
  color: #b8bfd3;
  font-size: 12px;
}
.cstatus {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  align-items: flex-end;
  color: #1bb978;
  background: #b9f4bc;
  border-radius: 8px;
  padding: 0.1rem 0.4rem;
  justify-content: center;
  width: 105px;
}
.pending {
  text-align: center;
  color: #ff0000;
  background: #fce4e4;
  border-radius: 8px;
}
.dspl {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #979797;
  padding-top: 1rem;
  align-items: center;
  justify-content: space-between;
}
.leftimg {
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
}
.rightimg {
  width: 30px;
  cursor: pointer;
}
.addone {
  color: #3965ff;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.wrarr {
  border: 1px solid;
  padding: 0.4rem 1.3rem;
  border-radius: 19px;
}
.rtt {
  justify-content: flex-start;
  padding-left: 1px;
  padding-top: 0.5rem;
}
.ch11 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: normal;
}
.ch11 > a {
  color: #001833;
}
.writeicon {
  width: 15px;
  margin-right: 0.4rem;
}
.ch12 {
  font-size: 19px;
  font-weight: bolder !important;
  letter-spacing: normal;
}
.smll {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filechan {
  flex: none;
  order: 0;
  align-self: center;
  margin: 10px 0px;
  background: #F2461B;
  border-radius: 8px;
  font-size: 16px;
  color: #fff;
  letter-spacing: normal;
  display: unset;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  font-weight: 400;
}

.deleteImage {
  flex: none;
  order: 0;
  align-self: center;
  margin: 10px 0px;
  background: #fff;
  border-radius: 8px;
  font-size: 16px;
  color: #F2461B;
  letter-spacing: normal;
  display: unset;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  border: 1px solid #F2461B;
  border-radius: 8px;
}
.infoforimage {
  font-size: 12px;
  letter-spacing: normal;
  background: #3964ff23;
  color: #3965ff;
  padding: 0.5rem;
  font-weight: 400;
  margin-left: 0.5rem;
}
.kislsree {
  margin: 0rem 2rem;
}
.paddn1 {
  padding: 0px;
}
.cardttx {
  padding-top: 0.5rem;
  font-size: 11px;
  font-weight: normal;
  color: #a5a5a5;
  text-align: center;
}
.notpaidtext {
  color: #fff;
  padding-left: 2rem;
}

.notpaid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #001833;
  border-radius: 8px;
  width: 96%;
  margin-top: 2rem;
  padding: 1rem;
}
.upss {
  padding: 0.4rem 0.6rem;
}
.upss1 {
  padding: 0.4rem 0.6rem;
  width: max-content;
  margin: auto;
  margin-top: 0.5rem;
}
.notpaid1 {
  padding-left: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .notpaid1,
  .notpaidtext {
    padding-left: 0px;
    padding-left: 0px;
    padding-bottom: 0.4rem;
    padding-top: 0.5rem;
  }
  .cname1,
  .cdate1,
  .ctime1,
  .cdate,
  .ctime {
    min-width: 86px;
  }
  .notpaid {
    flex-wrap: wrap;
  }
  .wrapc2 {
    margin-right: 3rem;
  }
  .notpaid1 {
    padding-left: 0px;
    flex-direction: column;
  }
  .upss {
    margin: auto;
  }
  .ctime {
    font-size: 14px;
  }
}
.failedNotice {
  width: 150px;
}
.onhno {
  text-align: center;
  font-weight: 600;
  font-size: large;
  letter-spacing: normal;
}
.kitemwrapper > img {
  width: 36px;
}
.bgstrength {
  background: white;
  border-top: 2px solid #eb5757;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.bgweakness {
  background: white;
  border-top: 2px solid #3965ff;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.StrongCareer {
  background: white;
  border-top: 2px solid #1bb978;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.AverageCareer {
  background: white;
  border-top: 2px solid #f2994a;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.weakCareer {
  background: white;
  border-top: 2px solid #dab22f;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.CareerDrivers {
  background: white;
  border-top: 2px solid #b7eaff;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.Personality {
  background: white;
  border-top: 2px solid #F2461B;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.AverageCareer {
  background: white;
  border-top: 2px solid #f2994a;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.BusinessEX {
  background: white;
  border-top: 2px solid #fea0da;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}
.WorkStyle {
  background: white;
  border-top: 2px solid #9b51e0;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}

.JobFunction {
  background: white;
  border-top: 2px solid #ffbc41;
  box-shadow: 2px 8px 16px rgba(57, 101, 255, 0.16);
  border-radius: 8px;
}

.loop11 {
  padding: 1rem 0px;
  padding-bottom: 0.5rem;
}
@media (max-width: 770px) {
  .prm {
    flex: 0 0 100.333333%;
    max-width: 100.333333%;
  }
  .kitemwrapper {
    width: 230px;
    height: 191px;
  }
  .insightss {
    text-align: center;
  }
}
.firstqq {
  padding-left: 3rem;
  padding-top: 1rem;
}
.sss12 {
  padding-right: 4rem;
  font-size: 15px;
  letter-spacing: normal;
  font-weight: 500;
}
.yudd1 {
  letter-spacing: normal;
  font-weight: 500;
  color: #001833;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 15px;
}
.dv-star-rating-star {
  margin-bottom: 0px !important;
}
.sss1a {
  padding-left: 0.2rem;
}
.userimg {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}
.userimg22 {
  padding-left: 0rem;
  padding-right: 0.5rem;
}
.viewall {
  text-align: right;
  color: #3965ff;
  font-size: 14px;
  padding-top: 0.5rem;
  cursor: pointer;
  width: 93%;
}
.firstoffour {
  height: 120px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  flex: 1;
  flex-wrap: wrap;
  min-width: 150px;
  color: #9fa2b4;
  margin-bottom: 1rem;
  border: 1px solid #dfe0eb;
}
.firstoffour:hover .mmber:hover {
  color: #3751ff !important;
}
.firstoffour:hover {
  border: 1px solid #3751ff;
  color: #3751ff !important;
}

.fouri1a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.greenbgcont {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #1bb978;
  background: #d8f9da;
  border-radius: 8px;
  padding: 1rem;
  width: 93%;
  margin-bottom: 1rem;
}
.greengood {
  width: 25px;
  margin-right: 1rem;
}
.mmber {
  font-size: 16px;
  text-align: center;
}
.mmber1 {
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 12px;
  color: #252733;
  font-weight: bold;
}
.mmber1:hover {
  color: inherit;
}
.mmber1 {
  font-size: 20px;
  text-align: center;
  letter-spacing: 1px;
}
.wwrap {
  display: flex;
  justify-content: space-between;
}
.ssoso {
  padding: 0.1rem 2rem;
}
.clarity12b {
  background: #fefefe;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.26);
  color: #F2461B;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.ssds1 {
  display: flex;
  flex-direction: column;
}
.hhius {
  display: contents;
}
.searchi {
  width: 19px;
  position: relative;
  top: 1.9rem;
  left: 0.5rem;
}

.searchinput {
  padding-left: 1.9rem;
  color: #ced4da !important;
}
.searchinput::placeholder {
  color: #ced4da;
}
.useri1222 {
  background: #fefefe;
  box-shadow: 0px 2px 6px rgba(24, 33, 77, 0.25);
  border-radius: 8px;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #001833;
  padding: 0.5rem 2rem;
}
.sjsso {
  width: 10%;
}
.sjsso1 {
  width: 80%;
}

.mssaag {
  padding-top: 3rem;
}
.messagedetails {
  padding-top: 0.4rem;
}
.useremail11 {
  color: #ced4da;
  padding-left: 1rem;
  font-size: 13px;
}
.username11 {
  color: #18214d;
  font-weight: 500;
}
.tymeline {
  text-align: center;
  font-weight: 500;
}
.red112 {
  background: #3965ff !important;
  color: #fff;
  border-radius: 8px !important;
}
.accordion > .card:last-of-type {
  border-radius: inherit;
}
.cllsls {
  background: inherit !important;
  color: inherit !important;
}
.cllsls1 {
  color: #fff;
  padding-left: 0px;
  font-weight: 500;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.card-header {
  background-color: inherit;
  border: none;
  cursor: pointer;
  letter-spacing: normal;
}
.accas1 {
  font-weight: 500;
  font-size: 14px;
}
.coloredtapping {
  border-top: 5px solid #F2461B;
}
.accas2 {
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
}
.card-body {
  padding-top: 0px;
}
.prclogo {
  width: 200px;
  margin-left: 12px;
}
.precision {
  width: 100%;
}
.flessz {
  display: flex;
  justify-content: space-between;
}
.detailss1 {
  padding-top: 1rem;
}
.accas1 {
  margin-bottom: 0px;
  font-weight: 600;
}
.lswid {
  width: 96%;
}
@media (min-width: 1024px) {
  .csfitscore {
    width: 96% !important;
  }
}
.reess {
  width: 96%;
}
.ssdd112 {
  background: #1d1d47;
  border-radius: 8px;
  display: flex;
  padding: 1rem;
  color: #fff;
  margin-top: 1rem;
  padding-left: 3rem;
  align-items: center;
  flex-wrap: wrap;
}
.emp12c {
  width: 56%;
  padding-left: 1rem;
  min-width: 200px;
}
.emp121 {
  margin-bottom: 0px;
  margin-top: 0.5rem;
}
.emp12a {
  font-weight: 200;
  font-size: 14px;
  padding-top: 0.5rem;
}
.gfts {
  background: linear-gradient(225deg, #a6d6ff 0%, #0084f4 100%);
  border-radius: 8px;
  display: inline;
  align-items: center;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: center;
  margin: 10px 0px;
  padding: 0.6rem 2rem;
  cursor: pointer;
}
.gfts1 {
  text-align: right;
  min-width: 200px;
}
@media (max-width: 800px) {
  .emp12c {
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
  .ssdd112 {
    justify-content: center;
    padding-left: 0px;
    text-align: center;
  }
  .gfts1 {
    margin-left: 0px;
    margin-bottom: 1rem;
    text-align: center;
  }
  .firstoffour {
    max-width: 191px;
  }
}
.cww {
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  flex-wrap: wrap;
  width: 96%;
  padding-left: 1rem;
  padding-bottom: 0.6rem;
}
.cww11 {
  color: #979797 !important;
}
.notpaidtext1 {
  font-size: 16px;
}
.smtd {
  font-size: 14px;
  text-align: center;
}
.notppd {
  width: 90%;
  margin-top: 0.3rem;
  background: #18214d;
}
.zeropad {
  padding-left: 0px;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
}

.graybgds {
  background: #f8f8f8;
}
.graybgds .notpaidtext {
  color: #a5a5a5 !important;
}
.smtdis,
.smtdis > a {
  background: #a5a5a5;
  border: 1px solid #a5a5a5;
  cursor: not-allowed;
}
.smtdis:hover {
  background: #a5a5a5;
  border: 1px solid #a5a5a5;
}
.caurtn {
  width: 35px;
}
.tdw1 {
  width: 19%;
  text-align: left;
  justify-content: end;
}
.eplimit {
  width: 158px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block !important;
  overflow: hidden;
}
.tdw0 {
  width: 158px !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  text-decoration: none;
  display: block !important;
  margin-right: 2.1rem;
}
/* CV Dashboard Styling*/
.edittt {
  position: absolute;
  width: 95%;
  text-align: end;
}
.edit_icon {
  margin-top: 0.8rem;
  width: 18px;
}
.bout {
  border-radius: 8px;
  border: 1px solid #dedddd;
  height: 110px;
  padding-right: 3rem !important;
}
.add_icon {
  width: 20px;
}
.cvexperience {
  padding-left: 40px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}
.buildingbg {
  background: #dedddd;
  border-radius: 8px;
  padding: 1rem 2.5rem 1rem 1.15rem;
  height: 80px;
}
.building {
  width: 50px;
}
.cvexp {
  font-size: 13px;
  line-height: 23px;
  padding-left: 3rem;
  padding-bottom: 0rem;
}
.role,
.company {
  font-weight: 600;
}
.time {
  color: #a4a6b3;
}
.showmore {
  color: #936dff !important;
  font-size: 13px;
  text-decoration: none;
  margin-left: 9rem;
}
.showmore:hover,
.showmore:active {
  color: #936dff;
  font-size: 14px;
}
.ll122 {
  margin-left: 1rem;
  margin-top: 0.4rem;
}
.skill_row {
  padding-top: 1rem;
}
.skills {
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dlete {
  margin-left: 8px;
}
.skill_cancel {
  width: 18px;
}
.newrowla {
  margin-bottom: 0rem;
}
.cveducation {
  border: 1px solid #dedddd;
  border-radius: 8px;
  font-size: 13px;
  display: flex;
  line-height: 23px;
  padding: 1.3rem 1.1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 97%;
}
.cvedu {
  width: 17px;
}
.sch_details,
.ref_details {
  padding-left: 1.2rem;
}
.school,
.name {
  font-weight: 600;
}
.course,
.location,
.mail,
.phone,
.relation {
  font-weight: 500;
}
.edit_descrip {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4rem;
  color: #936dff !important;
  text-decoration: none;
  cursor: pointer;
}
.edit_descrip:hover,
.edit_descrip:active {
  color: #936dff;
  font-size: 14px;
}
.drop {
  width: 12px;
}
.cvreference {
  border: 1px solid #dedddd;
  border-radius: 8px;
  font-size: 13px;
  display: flex;
  line-height: 23px;
  padding: 1.3rem 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 97%;
}
.cvsocial {
  font-size: 13px;
  display: flex;
  justify-content: space-around;
  padding: 1.5rem 0rem;
}
.social1 {
  color: #936dff;
}
.printcv {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4rem;
}
.savecv {
  padding-bottom: 8px;
  text-align: center;
  padding-top: 8px;
}

@media (max-width: 1200px) {
  .building {
    width: 40px;
  }
  .buildingbg {
    height: 70px;
    padding: 1.9% 2% 2% 1.6%;
  }
}

@media (max-width: 1000px) {
  .edittt {
    width: 93%;
  }
  .cvsocial {
    margin-left: 0rem;
  }
  .savecv {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .roww {
    max-height: 120px;
  }
  .showmore {
    margin-left: 7.5rem;
  }
}

@media (max-width: 800px) {
  .prm {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .idds {
    font-size: 25px;
  }
  .ch11 {
    font-size: 14px;
  }
  .liee {
    font-size: 14px;
  }
  .building {
    width: 35px;
  }
  .buildingbg {
    height: 60px;
    padding: 1.9% 8.5% 2% 2%;
  }
  .jobr,
  .cvexp {
    font-size: 13px;
  }
  .cvexp {
    padding-left: 2rem;
  }
  .showmore,
  .edit_descrip {
    font-size: 12px;
  }
  .showmore:active,
  .showmore:hover,
  .edit_descrip:active,
  .edit_descrip:hover {
    font-size: 13px;
  }
  .showmore {
    margin-left: 6rem;
  }
  .plusnew1 {
    font-size: 11px;
  }
  .printcv {
    padding-bottom: 2rem;
  }
  .roww {
    max-height: 100px;
  }
  .skill_row {
    padding-top: 0rem;
  }
  .edittt {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .idds {
    font-size: 22px;
  }
  .ch11,
  .liee {
    font-size: 12px;
  }
  .whatdoudo {
    font-size: 13px;
  }
  .jobr,
  .cvexp,
  .cveducation,
  .cvreference,
  .cvsocial {
    font-size: 12px;
  }
  .printcv {
    font-size: 11px;
  }
  .savecv {
    padding-bottom: 3px;
    padding-top: 3px;
  }
  .edit_icon {
    width: 14px;
  }
  .building {
    width: 27px;
  }
  .buildingbg {
    height: 48px;
    width: 48px;
  }
  .cvexp {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .prm {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 400px) {
  .kdashheader {
    max-height: 170px;
  }
  .edittt {
    width: 86%;
  }
  .bout {
    padding-right: 2rem;
  }
  .jobr,
  .cvexp,
  .cveducation,
  .cvreference,
  .cvsocial {
    font-size: 11px;
  }
  .cvexp {
    padding-left: 1rem;
  }
  .showmore {
    margin-left: 4.5rem;
  }
  .cvsocial {
    flex-direction: column;
    padding: 1rem;
  }
  .social1 {
    padding-bottom: 0.4rem;
  }
}
.aboutprv {
  height: 100px;
  width: 100%;
  padding: 1rem;
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 8px;
  overflow-y: auto;
}
.hpadd {
  padding-left: 0px;
  display: inline;
}

.task112 {
  word-spacing: normal;
  padding-left: 1rem;
}
/*All Tasks Page Styling*/
.firstqq {
  padding-top: 2rem;
}
.begin {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}
.closeview {
  cursor: pointer;
}
.create_task {
  color: #ffffff;
  background: #F2461B;
  border-radius: 5px;
  font-size: 12px;
  padding: 0.6rem 1.5rem;
  width: fit-content;
  height: fit-content;
  margin-right: 8rem;
  font-weight: 600;
  cursor: pointer;
}
.create {
  width: 13px;
  margin-left: 6px;
  margin-bottom: 3px;
}
.view_list {
  font-size: 13px;
  font-weight: 500;
}
.yellowbg {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.4px;
  color: #ffad08;
  background: #fefde6;
  border-radius: 8px;
  padding: 1rem;
  width: 93%;
  margin-bottom: 1rem;
  margin: auto;
  margin-left: 0rem;
}
.yellowgood {
  width: 32px;
  margin-left: 0rem;
  margin-right: 1rem;
}
.task_table {
  font-size: 13px;
  color: #979797;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: space-around;
  padding: 1.5rem;
  margin-right: 30%;
  width: 75%;
  margin-left: -5%;
}
.task_title {
  margin-right: 4rem;
}
.task_duration {
  margin-right: 0rem;
}
.task_time {
  margin-right: 0rem;
}
.tasklist {
  font-size: 13px;
  padding: 1.1rem 3rem 1.1rem 2.2rem;
}
.todo_name {
  min-width: 135px;
  max-width: 170px;
  flex: 30%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
}
.todo_date {
  font-size: 13px;
  min-width: 90px;
  max-width: 150px;
  flex: 10%;
}
.todo_time {
  max-width: 150px;
  min-width: 100px;
  flex: 20%;
}
.todo_status {
  border-radius: 5px;
  font-size: 12px;
  padding: 0.2rem 1.2rem;
  width: 6.5rem;
  height: fit-content;
  font-weight: 600;
}
.todoo {
  max-width: 150px;
  min-width: 140px;
  flex: 20%;
}
.todo_button {
  border-radius: 5px;
  font-size: 12px;
  padding: 0.35rem 1.5rem;
  width: fit-content;
  height: fit-content;
  font-weight: 600;
  cursor: pointer;
}
.next_page {
  color: #979797;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 4rem 1rem 1.8rem;
}
.page_num {
  color: #18214d;
}
.page_change {
  width: 30px;
  margin-left: 0.5rem;
  cursor: pointer;
}
.modcomplete .modal-dialog {
  width: 45%;
}
textarea,
.task_det:focus {
  border: #0084f4 !important;
  outline: 1px solid #ebe6e6;
  box-shadow: none;
}
input,
.create_det:focus {
  border: #0084f4 !important;
  outline: 1px solid #ebe6e6;
  box-shadow: none;
}
.lowerr {
  display: none;
  color: #979797;
}

@media (max-width: 1200px) {
  .task_time {
    margin-right: 0.5rem;
  }
  .todo_button {
    padding: 0.35rem 0.5rem;
  }
  .yellowgood {
    margin-left: 0rem;
  }
  .tasklist {
    padding-right: 1rem;
  }
}

@media (max-width: 1000px) {
  .yellowgood {
    width: 28px;
    margin-right: 1rem;
  }
  .yellowbg {
    font-size: 13px;
  }
  .task_table {
    margin-left: -2%;
    width: 70%;
  }
  .task_title {
    margin-right: 20%;
  }
  .task_duration {
    margin-right: 10%;
  }
  .task_time {
    margin-right: 10%;
  }
  .task_status {
    margin-right: 2%;
  }
  .tasklist {
    width: 97%;
  }
  .todo_status {
    padding: 0.2rem 1rem;
    width: 5.5rem;
  }
  .todo_name {
    max-width: 120px;
    min-width: 100px;
  }
  .durr {
    margin-right: 2%;
  }
  .todo_date {
    max-width: 100px;
    min-width: 80px;
  }
  .todo_time {
    max-width: 150px;
    margin-right: 5%;
    min-width: 110px;
  }
  .stat {
    width: 100px;
  }
}

@media (max-width: 800px) {
  .yellowbg {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .task_table {
    display: none;
  }
  .tasklist {
    padding-bottom: 25px;
    align-items: flex-start;
    width: 70%;
    font-size: 14px;
    margin-left: 15%;
  }
  .todo_name,
  .todo_date,
  .todo_time {
    width: 100%;
    max-width: 100%;
    padding-top: 1px;
    justify-content: flex-start;
  }
  .lowerr {
    display: block;
    padding-top: 10px;
    width: 100%;
    font-size: 13px;
  }
  .stat {
    padding-top: 10px;
  }
  .durr {
    margin-right: 7%;
  }
  .todo_button {
    margin-top: 8%;
    padding: 0.2rem 0.5rem;
  }
  .titl {
    flex: 100%;
  }
  .weeks {
    flex: 40%;
  }
  .durr {
    flex: 40%;
  }
  .stat,
  .todoo {
    flex: 40%;
    justify-content: flex-start;
    padding-top: 1.2rem;
  }
  .stat {
    padding-top: 1.8rem;
  }
  .todoo {
    margin-right: 1rem;
  }
}

@media (max-width: 770px) {
  .tasklist {
    width: 60%;
  }
  .durr {
    margin-right: 8%;
  }
}

@media (max-width: 670px) {
  .todoo {
    margin-right: 0rem;
  }
  .tasklist {
    padding-left: 1rem;
  }
}

@media (max-width: 600px) {
  .tasklist {
    width: 85%;
    margin-left: 4%;
    padding-left: 2rem;
  }
  .durr {
    margin-right: 9%;
  }
  .todo_date {
    padding-right: 2rem;
  }
  .todoo {
    margin-right: 8%;
  }
  .create_task {
    margin-right: 0rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 500px) {
  .yellowbg {
    width: 90%;
  }
  .firstqq {
    padding-left: 1rem;
  }
  .tasklist {
    width: 80%;
    padding: 1.1rem 1rem 1.1rem 1rem;
  }
  .todo_button {
    margin-top: 4%;
  }
  .todoo {
    margin-right: 5%;
  }
  .modcomplete .modal-dialog {
    width: 100%;
    margin: auto;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
  width: -moz-max-content;
}
@media (max-width: 545px) {
  .durr {
    margin-right: 11%;
  }
  .create_task {
    width: 150px;
    text-align: center;
  }
  .begin {
    flex-wrap: wrap;
  }
}

@media (max-width: 400px) {
  .create {
    display: none;
  }
  .tasklist {
    width: 100%;
    margin-left: 0%;
    padding: 1.1rem 1rem 1.1rem 2rem;
  }
  .yellowbg {
    width: 90%;
  }
}

@media (max-width: 350px) {
  .tasklist {
    padding: 1.1rem 1rem 1.1rem 1rem;
  }
  .todoo {
    margin-right: 2%;
  }
}

/*Todo Overview Responsiveness*/
.newtt {
  width: 85%;
  margin-left: 0%;
}
.nuname {
  margin-right: 10%;
}

@media (max-width: 1100px) {
  .nuname {
    margin-right: 8%;
  }
}

@media (max-width: 1000px) {
  .newtt {
    width: 89%;
  }
  .newdur {
    margin-right: 2%;
  }
  .nudate {
    margin-right: 4%;
  }
}

@media (max-width: 900px) and (min-width: 801px) {
  .nuname {
    margin-right: -2%;
    width: 120px !important;
  }
  .newtitl {
    margin-right: 0rem;
    width: 20% !important;
    min-width: 100px;
    display: flex !important;
    justify-content: flex-start;
  }
  .listit {
    justify-content: flex-start;
  }
}

@media (max-width: 800px) {
  .listit {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .newtitl {
    margin-right: 3.1rem;
  }
}

.skill-skill {
  height: 70%;
    margin: 0 10px;
    overflow-y: auto;
}
.skill-skill > div {
  margin-left: 5px;
  margin-bottom: 5px;
}
.skill-skill-input {
  padding-top: 4.5rem;
}
.display-skill {
  height: 94%;
    overflow-y: auto;
    margin-top: 0;
    margin-left: 5px;
}
.display-skill > div {
  margin-left: 5px;
  margin-bottom: 5px;
}
.rfheader{ 
    font-size: 1.8em;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #0c1232;
}
.rfborder{
    width: 80px;
    height: 2px;
    text-align: center;
    background-color: #F2461B;
}
.rfborderw{
    display: flex;
    justify-content: center;
    padding-top: .3rem;
}
.formwrap{
    padding-top: 4rem;
}
.reqfield{
    color: #F2461B;
}
.reqname{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #e6e6e6;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-top: .6rem;
}
.form-control:focus{
    outline: none;
}
input.reqname::placeholder  {
color: #e6e6e6;
font-size: 15px;
transition: 1s ease-in-out;
}

input.reqname:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #e6e6e6;
}
  
input.reqname::-ms-input-placeholder { /* Microsoft Edge */
    color: #e6e6e6;
    transition: 1s ease-in-out;
}
.questionarrow{
    width: 10px;
    color: #e6e6e6;
    padding-right: .5rem;
    font-size: 11px;
    font-weight: 800;
}
.questionno{
    color: #e6e6e6;
}
.offserr{
    position: relative;
    right: 1rem;
}
.llsss{
    margin-bottom: 3rem;
}
.form-control:focus {
    color: inherit;
    background-color: #fff;
    border-color: none; 
    outline: none;
    box-shadow: none;
}
input.reqname::-ms-input-placeholder :focus{
    transition: 1s ease-in-out;
}
.boxwrapper{
    width: 121.8px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #F2461B;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    cursor: pointer;
    padding-left: 4px;
}
.contentanswer{
    margin: auto;
}
.alphaIndex{
    color: #F2461B;
    border: .5px solid #9c12576c;
    font-size: 11px;
    border-radius: 2px;
    width: 121px;
    text-align: center;
    padding: 1.6px 5.9px;
    position: relative;
    right: 2rem;
    bottom: 2px;
}
.answ{
    position: relative;
    right: 10.5px;
}
.boxgroup{
    margin-top: .5rem;
}
@media(max-width:500px){
    .formwrap {
        margin-left: 1.1rem;
    }
}
.signupimg{
    width:100%;
    max-width: 425px;
}
.kigenni1{
    color: #F2461B;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    padding-bottom: 2rem;
}
.formavatar{
    width: 13px;
    position: relative;
    top: 1.78rem;
    left: .6rem;
}
.selectprof{
    margin-top: 0rem;
}
.selecss{
    padding-left: 1.8rem;
    font-size: .9em;
    color: #343434a2;
}
.bvbcm.form-group{
    margin-bottom: 0px;
}
.mjcn{
    /* font-family: Ubuntu; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #18214D;
    padding-bottom: .4rem;
}
.bcbv{
    justify-content: center;
}
button.subbtn.ncn.btn.btn-primary{
    margin-top: 0.9rem;
}
.kigenni2{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #F2461B;
}
.mo{
    display: flex;
    justify-content: flex-end;
}
.kli{
    padding-top: 3rem;
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    padding-bottom: 4rem;
    margin-top: 3rem;
}
@media(min-width:768px){
    .kli{
        margin-top: 5rem;
        margin-left: 5rem;
        margin-right: 5rem;
    }
}
@media(max-width:500px){
    .mo {
        justify-content: center;
    }
    .signupimg {
        width: 50%;
    }
}
.signwa{
    font-size: 1.7em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #343434;
}
.signwa1{
    font-size: 1.4em;
    font-weight: 100;
    font-stretch: normal;
    padding-bottom: 1.3rem;
    font-style: normal;
    line-height: 1.29;
    padding-top: .5rem;
    letter-spacing: normal;
    color: #343434;
}
.signenter{
    font-size: 1em;
    padding-bottom: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
}
.feild3{
    border: solid 1px #707070;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #343434;  
}

.field3.form-control {
    color: #343434a2;
    font-size: .9em;    
    padding-left: 2rem;
}
.field3::placeholder {
    color: #343434a2;
    font-size: .9em;    
}
.subbtn{
    width: 100%;
    border-radius: 4px;
    background-color: #F2461B;
    border-color: #F2461B;
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding: .6rem;
}
.subbtn:focus{
    outline: none;
    border: none;
}
.subbtn:hover{
    background-color: #F2461B;
    border-color: #F2461B;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #F2461B;
    border-color: #F2461B;
}
.alreadyhave{
    font-size: .9em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    padding-top: .8rem;
    padding-bottom: .8rem;
    color: #343434;
}
.text-divider{margin: .5em 0; line-height: 0; text-align: center;color: #495057}
.text-divider span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
}
.centeredline{
    border: 1px solid #eff0f1;
}
.logn{
    color: #0070e3;
    cursor: pointer;
}
.socialwrapper{
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
}
.socialIcons1{
    border: 1px solid #345495;
    border-radius: 5px;
    width: 25%;
    text-align: center;
    cursor: pointer;
}
.socialIcons2{
    border: 1px solid #dd5145;
    border-radius: 5px;
    width: 25%;
    max-width: 26%;
    text-align: center;
    cursor: pointer;
}
.socialwrapper >img{
    cursor: pointer;
}
.socialIcons3{
    border-radius: 5px;
    border: solid 1px #345495;
    cursor: pointer;
    width: 25%;
    text-align: center;
}
.clscc{
    color: #dd5145;
    font-size: 14px;
}
@font-face {
  font-family: "Sailec-Thin";
  src: url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.eot");
  src: url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.svg#Sailec-Thin")
      format("svg");
}
@font-face {
  font-family: "Sailec-Light";
  src: url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.eot");
  src: url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/2fe381b3d8cf4ee5f331668970d8a65a.svg#Sailec-Light")
      format("svg");
}
@font-face {
  font-family: "Sailec-Medium";
  src: url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.eot");
  src: url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.svg#Sailec-Medium")
      format("svg");
}
@font-face {
  font-family: "Brfirma-Regular";
  src: local("Brfirma-Regular"),
    url("../../../Brfirma/BRFirma-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-Bold";
  src: local("Brfirma-Bold"),
    url("../../../Brfirma/BRFirma-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-BoldItalic";
  src: local("Brfirma-BoldItalic"),
    url("../../../Brfirma/BRFirma-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Brfirma-ExtraLight";
  src: local("Brfirma-ExtraLight"),
    url("../../../Brfirma/BRFirma-ExtraLight.ttf") format("truetype");
}
.top-layer {
  width: 100%;
  height: 10.5px;
}
ul {
  list-style: none;
  padding-left: 0px;
  text-align: center;
}
.txtheavy {
  font-weight: 700;
}
.firstrow {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: 7%;
}
.firstrowtxt {
  /* font-family: "Ubuntu"; */
  font-size: 2.937em;
  font-stretch: normal;
  padding-top: 5rem;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #F2461B;
}
.nav-wrapper {
  display: none;
  justify-content: space-between;
  padding-left: 9rem;
  padding-right: 9rem;
  padding-top: 1rem;
}
.nav_title {
  display: flex;
  color: #150538;
  font-size: 14px;
  font-weight: 300;
}
.ftweight {
  font-weight: 500;
}
.nav_title1 {
  display: flex;
  font-size: 14px;
}
div > .title1 {
  margin: auto;
  padding-left: 1rem;
}
.title1 > .title_l {
  color: #F2461B;
}
.title1 > a .title_l {
  color: #F2461B;
}
div > .title {
  margin: auto;
  padding-left: 2rem;
}
.title_l {
  border: 0.5px solid #F2461B;
  border-radius: 3.5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  text-align: center;
  letter-spacing: normal;
  padding: 0.4rem 1rem;
  width: 97px;
  color: #F2461B;
}
.title_l:hover {
  color: inherit;
}
button:focus {
  outline: none;
}
button {
  cursor: pointer;
}
.title_t {
  border: 0.5px solid #F2461B;
  border-radius: 3.5px;
  background-color: #F2461B;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  width: 97px;
  text-align: center;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0.4rem 1rem;
}
.logo_clarity {
  position: relative;
  right: 2.2rem;
}
.Clarity-is-the-solution {
  font-size: 0.6em;
  font-style: normal;
  line-height: 1.53;
  padding-top: 1rem;
  /* font-family: "Ubuntu" */;
  letter-spacing: normal;
  text-align: left;
  color: #150538;
}
.We-are-like {
  width: 794.2px;
  height: 54.9px;
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #F2461B;
}
.Rectangle-8 {
  padding: 1rem;
  padding-top: 4rem;
  background-color: #f9f9f9;
}
.homebanner1 {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  border-radius: 12.8px;
}
.secondRow {
  padding: 3rem 4rem;
  padding-bottom: 7rem;
  margin-bottom: 4rem;
}
.secondimagearea {
  padding-top: 4rem;
  padding-left: 3rem;
}
.Clarity-of-Thought {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #150538;
  padding: 0rem 1rem;
}
.Clairty-lorem {
  font-size: 14px;
  /* font-family: "Ubuntu" */;
  /* padding-top: 1rem; */
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b3;
  position: relative;
  top: 1rem;
}
.clarity-card {
  text-align: center;
  width: 120px;
  margin-left: 2rem;
  margin-top: 1rem;
}
.Ellipse-4 {
  height: 71px;
  margin: auto;
  width: 53%;
}
.Ellipse-x4 {
  height: 71px;
  margin: auto;
  width: 60%;
}
.Ellipse-2 {
  /* width: 71%; */
  /* height: 80%; */
  color: #ffffff;
  background-color: #f7931e;
  border-radius: 50%;
  padding: inherit;
}
.Ellipse-3 {
  color: #ffffff;
  border-radius: 50%;
  padding: 16px 13px;
}
.Ellipse__1 {
  color: #ffffff;
  border-radius: 50%;
  padding: 16px 8px;
}
.cards-section1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 0.5rem;
}
.borderc {
  padding: 26px 19px;
}
.straight_line {
  border-left: 1px dotted;
}
.How-it-works {
  height: 52px;
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #F2461B;
}
.homebanner3 {
  width: 90%;
  height: 90%;
  border-radius: 16.2px;
}
.homebanner5 {
  width: 100%;
  z-index: 2;
}

.thirdrow {
  padding-left: 2rem;
  padding-bottom: 4rem;
}
.oneimg {
  width: 100%;
}
.Assess {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: normal;
  text-align: left;
  color: #F2461B;
}
.The-best-way {
  padding-top: 0.7rem;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #2c2147;
  padding-left: 3.5rem;
}
.howcardwrapper {
  margin-bottom: 2rem;
}
.fourthsecrow {
  background-color: #F2461B;
  flex-wrap: wrap;
  margin-top: 4rem;
}
.I-found {
  font-size: 1.1em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 4rem 1rem 0rem 1rem;
  margin-left: 2rem;
}
.--Marta-Vaughn {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 3rem;
  padding-top: 0.8rem;
  padding-bottom: 1.1rem;
}
.readmore {
  font-size: 14px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  /* font-family: "Ubuntu" */;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  background-color: #F2461B;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 3px;
}
.Knowing-yourself {
  font-size: 1.875em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: center;
  color: #F2461B;
}

.TAKE-FREE-ASSESSMENT {
  font-size: 16px;
  background-color: #f7931e;
  padding: 1rem 2rem;
  border: 0px solid transparent;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
}

.fifthsecrow {
  margin-top: 5rem;
  padding-top: 2rem;
  margin-bottom: 4rem;
}
.takeAssesment {
  margin-top: 2rem;
}
.footer_tit {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #2c2147;
  text-align: left;
}
.About-Us {
  /* font-family: "Ubuntu"; */
  font-size: 14px;
  padding-top: 1rem;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #0f1a29;
}
.footer_first_wrapper {
  display: flex;
  justify-content: space-around;
}
.iconwrapper {
  margin-top: 2rem;
  padding-left: 6rem;
}
.iconimage {
  width: 1.875em;
}
.reserved {
  /* font-family: "Ubuntu"; */
  font-size: 14px;
  padding-top: 1rem;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #0f1a29;
  padding-bottom: 2rem;
}
.circle {
  height: 20px;
  width: 20px;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
}
.listwraperMob a {
  color: white;
}
@media (min-width: 780px) {
  .top-layer {
    background-color: #fff;
  }

  .clarity_logo {
    display: none;
  }
  .tymes {
    transform: rotateY(180deg);
  }
  .reserved {
    /* font-family: "Ubuntu"; */
    font-size: 14px;
    padding-top: 1rem;
    margin-left: 6rem;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #0f1a29;
    padding-bottom: 2rem;
  }
}
@media (min-width: 780px) {
  .nav-wrapper {
    display: flex;
  }
  .hamburger {
    padding-right: 0.5rem;
  }
  .clarity_logo {
    padding-top: 0.2rem;
    padding-left: 0.5rem;
  }
  .top-layer {
    background-color: #F2461B;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
  }
  #wrapper > div {
    display: inline-block;
  }
  .hamburgerwrap {
    display: none;
  }
  .lakk {
    display: none;
  }
  /* fix the navbar at the top */
  .NavsectionFixed {
    position: fixed;
    background: #ffffffd8;
    z-index: 1;
    width: 100%;
    top: 0px;
  }
}
.firstassesbtn {
  font-size: 0.38em;
  letter-spacing: normal;
  width: 200px;
  color: #ffffff;
  cursor: pointer;
  background-color: #F2461B;
  padding: 0.6rem 0.7rem;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: center;
}
.homebanner0 {
  width: 100%;
}
@media (max-width: 579px) {
  .firstrow {
    padding-top: 1rem;
    margin-left: 0%;
  }
  .--Marta-Vaughn {
    margin-left: 0px;
  }
  .I-found {
    margin-left: 0px;
  }
  .reserved {
    width: 100%;
  }
  .firstassesbtn {
    font-size: 14px;
  }
  .Clarity-is-the-solution {
    font-size: 17px;
  }
  #wrapper > div {
    display: none;
  }
  .We-are-like {
    font-size: 1.7em;
  }
  .firstrowtxt {
    font-size: 1.7em;
  }
  .listwraperMob a {
    font-size: 19px;
  }
  .Knowing-yourself {
    font-size: 1.7em;
  }
  .TAKE-FREE-ASSESSMENT {
    font-size: 14px;
  }
  .listwraper {
    padding-top: 0px;
  }
  .shii11 {
    padding-top: 0px;
  }

  .How-it-works {
    font-size: 1.7em;
    text-align: center;
  }
  .I-found {
    font-size: 14px;
  }
  .ccss {
    margin-top: 2rem;
  }
}
@media (max-width: 779px) {
  .homebanner5 {
    bottom: -1.5rem;
    left: 1rem;
  }
  .Toastify__toast {
    width: 69%;
  }
  .Toastify__toast-container {
    text-align: -webkit-center;
  }
  .secondoso {
    margin-top: 1rem;
  }
  .flexsss {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.4rem;
    padding-right: 1rem;
    background: white;
    padding-left: 0.5rem;
    padding-bottom: 0.8rem;
    /* background: #ECECEC; */
  }

  .I-found {
    padding: 1rem 0rem 0rem 0rem;
  }
  .top-layer {
    height: 53.5px;
  }
  .top-layermobile {
    height: 9px;
    background-color: #F2461B;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
  }
  .iconwrapper {
    margin-top: 2rem;
    padding-left: 0rem;
    text-align: center;
  }
  .firstrowtxt {
    padding-top: 2rem;
  }
  .secondRow {
    padding: 3rem 0rem;
  }
  .secondimagearea {
    padding-left: 1rem;
  }
  .clarity-card {
    margin-left: 1rem;
  }
  .thirdrow {
    margin-left: 0px;
    padding-left: 1px;
  }
  .secondline {
    display: none;
  }
  .padleftabout {
    padding-left: 1rem;
  }
  .shii11 {
    padding-top: 0rem;
  }
}
.title_ll {
  background-color: #F2461B;
  color: white;
  border: none;
  border-radius: 34px;
  padding: 0.4rem 0.9rem;
  border: 1px solid #F2461B;
}
.title_ll2{
  background: none;
  color: #F2461B;
  font-weight: 400;
  border: 1px solid #F2461B;
  margin-right: .5rem;
}
.useravatar {
  border-radius: 50%;
  border: 1px solid #f7f1f4;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.useravatarwraper {
  border-radius: 50%;
  border: 1px solid #F2461B;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: table;
}
.line-top {
  width: 100%;
  /* height: 60px; */
  z-index: -1;
  background: transparent;
  border: none;
  border-top: dashed 1px #b3b3b3;
  padding: 61px 141%;
  border-radius: 58%;
  margin: -86px 76px 51px 34px;
}
@media (min-width: 1500px) {
  .line-top {
    margin: -86px 76px 51px 51px;
  }
}
.stepgood {
  width: 41.43px;
  min-width: 41.43px;
  max-width: 41.43px;
  padding: 0px;
  margin-right: 1rem;
}
.steptwo {
  width: 41.43px;
  min-width: 41.43px;
  max-width: 41.43px;
  padding: 0px;
  margin-right: 1rem;
}
/* .straight_line{
  border-left: 2px dotted gray;
  padding: 66px;
  position: relative;
  left: 2.2rem;
  z-index: -1;
} */
.line2 {
  width: 28px;
  background: #F2461B;
  height: 3px;
  margin-top: 5px;
}
.line1 {
  width: 28px;
  background: #001833;
  height: 3px;
  margin-top: 0px;
}
.line3 {
  width: 28px;
  background: #001833;
  height: 3px;
  margin-top: 5px;
}
.listwraper {
  color: white;
  font-size: 25px;
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.listwraperMob {
  font-size: 25px;
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.navmobbtn {
  border: 1px solid #F2461B;
  padding: 0.7rem 2rem;
  border-radius: 2.5rem;
}

.line1active {
  transform: rotateZ(206deg);
}
.firstImg {
  max-width: 270px;
  border-radius: 15px;
}
.firstImg1 {
  max-width: 270px;
  height: 100%;
  border-radius: 15px;
}
.secondoso {
  max-height: 413.27px;
  max-width: 270px;
  min-width: 270px;
  margin-right: 1rem;
  text-align: center;
  border-radius: 20px;
  background-color: #f8f8f8;
  margin-left: 5px;
}
.takecharge {
  text-align: center;
  font-weight: bolder;
  position: relative;
  bottom: 2.7rem;
  line-height: 1.2;
}
.shiftlefff .title_t {
  margin-left: 2rem;
}
.inner21 {
  background-color: orange;
  width: 60px;
  text-align: center;
  color: white;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid #f9f9f9;
  font-size: 12px;
  font-weight: 500;
}
.wraper22 {
  width: 60px;
  color: white;
  height: 60px;
  display: table;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0.3rem;
  border: 0.1px solid #b3b3b3;
  z-index: 3;
  position: relative;
}
.jsj {
  display: flex;
  align-items: center;
}
.uysh {
  width: 142px;
}
@media (max-width: 580px) {
  .uysh {
    width: 89px;
  }
  .flexsss {
    background: #fbfbfb;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.cards-section1 {
  margin-right: 1rem;
}
.kissa {
  display: flex;
  align-items: center;
}
.giveaa {
  font-size: 20px;
  padding-top: 4rem;
  line-height: 1.5;
  width: inherit;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #F2461B;
  word-spacing: 5px;
}

@media (max-width: 400px) {
  .giveaa {
    word-spacing: -1px;
  }
  .saedlogo {
    margin-left: 1rem;
  }
}
.saedlogo {
  margin-left: 1rem;
}
@media (min-width: 900px) {
  .hamburger {
    display: none;
  }
}

.ptod {
  padding-top: 0px !important;
}
.listwraper .gbn {
  text-align: left !important;
}
.goodlink {
  padding: 0.5rem 3rem;
  border: 2px solid #F2461B;
  border-radius: 30px;
  display: inline-block;
  margin-bottom: 2rem;
}
.saedlogo {
  width: 50px;
}

body {
  font-family: "Brfirma-Regular";
}
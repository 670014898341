.areawrappera{
    background: #32335A;
    border-radius: 2rem;
    height: 99%;
    transition: 1s ease-in-out;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: .41px;
    margin-top: unset;
    padding-left: 0.7rem;
    margin-top: 8px;
    margin-bottom: 8px;
}
.areawrapper1{
    display: flex;
    width: 100%;
    padding-top: 2.9px;
    padding-left: 3.5px;
    padding-right: 10px;
    padding-bottom: 4px;
    height: 39px;
    border-radius: 2rem;
    background: #ECECEC;
    justify-content: space-between;
    margin: .3rem;
    font-size: 14px;
    align-items: center;
}
.mrauto{
    margin: auto;
}
.areawrapperbnone{
    display: none;
}
.chartwar{
    display: flex;
    margin-right: 1rem;
}
.changeinnerflex{
    flex-direction: row-reverse;
}
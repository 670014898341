@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input:focus{
    border: #0084f4 !important;
    outline: 1px solid #ebe6e6 !important;
    box-shadow: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 430px) {
  .whatsapp {
    width: 40px;
    position: fixed;
    bottom: 2rem;
    left: 1rem;
    z-index: 100;
  }
}
@media(min-width:431px){
  .whatsapp{
    display: none;
  }
}
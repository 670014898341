.gooogg {
  max-width: 28px;
  margin-top: 2px;
  margin-bottom: 0.4rem;
}
.alreadyhave1 a {
  color: #444444 !important;
  font-size: 14px;
  text-align: center;
}
.alreadyhave1 {
  text-align: center;
}
.enter12 {
  font-style: normal;
  font-weight: 300;
  font-size: 1.7em;
  line-height: 50px;
  color: #001833;
}

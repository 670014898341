.gooogg {
  max-width: 28px;
  margin-top: 2px;
  margin-bottom: 0.4rem;
}
.alreadyhave1 a {
  color: #444444 !important;
  font-size: 14px;
  text-align: center;
}
.alreadyhave1 {
  text-align: center;
}
.grb {
  background: #1bb978;
  border-radius: 5px;
  color: #ffffff;
  border-color: #1bb978;
  width: 30%;
  min-width: 150px;
  margin: auto;
  margin-top: 1rem;
}

.grb:hover {
  background: #1bb978;
  border-radius: 5px;
  color: #ffffff;
  border-color: #1bb978;
}
.notallowed{
  cursor: not-allowed;
}
.paywrap1 {
  background: #f8f8f8;
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1rem;
  margin-top: 3rem;
}
.payw1{
  padding-top: 1rem;
}
.fstname {
  font-size: 14px;
  color: #001833;
  font-weight: bold;
}

.signwaaa {
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #001833;
  padding-left: 6.7rem;
}
@media(max-width:600px){
  .jcenter {
    margin: auto;
  }
}

.ourteamheader{
    font-size: 1.875em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #2c2147;
    margin-bottom: 2rem;
}
.padleftabout{
    padding-left: 6rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
}
.bglight{
    background-color: #f9f9f9;
}
.userimg {
    opacity: 0.93;
    border-radius: 50%;
    background-color: #f2f2f2;
    width: 80%;
    max-width: 80%;
    max-width: 192px;
    max-height: 191px;
}
.about-card-wrap {
    width: 270.9px;
    margin-bottom: 1rem;
    background-color: #f2f2f2;
    padding: 1rem 1rem;
}
.title >a {
    text-decoration: none;
    color: inherit;
}
.FounderCEO {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: right;
    color: #2c2147;
    padding-bottom: 1rem;
  }
  .usernameabout {
    width: 87px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #2c2147;
    padding-top: 1rem;
  }
  .wrap11{
      display: flex;
      justify-content: space-between;
  }
  .cardecor{
    position: relative;
    left: 1rem;
    top: 1rem;
  }
  .wrap123{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
  }

.We-are-lyk{
    width: 794.2px;
    font-size: 1.175em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #F2461B;
}
.We-are-w{
    font-size: 20px;
    font-weight: 500;
    padding-top: 1rem;
    padding: 5rem 0rem 2rem 0rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.99;
    letter-spacing: normal;
    text-align: center;
    color: #150538;
  }
  .firstImg1{
      margin-left: 1rem;
  }
  @media(min-width:780px){
    .wrap001{
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        justify-content: left;
    }
  }
 
  @media(max-width:779px){
    
    .wrap001 {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        justify-content: center;
    }
  }

  .-of-life-is{
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #150538;
    padding-top: 4rem;
  }
  .Do-work-that-counts {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    color: #F2461B;
    padding-top: 3rem;
    margin-bottom: 6rem;
  }
.aboutaccess{
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  font-weight: 400;
  letter-spacing: normal;
  text-align: left;
  color: #F2461B;
  display: flex;
  align-items: center;
}
.ab_cards-section1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: .5rem;
    flex-direction: column;
}
.aboutaccess span{
    color: #2c2147;
}
.b12{
    margin-top: 1rem;
    padding-bottom:1rem
}
.firsab{
    padding-bottom: 3rem;
}
.aboutimgonebg{
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 5rem;
    /* max-width: 163px;
    max-height: 190px; */
}
.aboutimgonesmal{
    width: 80%;
    max-width: 129px;
    max-height: 102px;
}
.aboutimgonesmall {
    margin-bottom: 1rem;
    width: 76%;
    /* max-width: 161px;
    max-height: 188px; */
}
@media(max-width:600px){
    .aboutimsec{
        display: none;
    }
    .padleftabout {
        padding-left: 1rem;
    }
    .firstrowtxt {
        font-size: 1.937em;
    }
    .We-are-w{
        font-size: 14px;
    }    
    .-of-life-is{
        font-size: 16px;
        line-height: 1.4;
    }
    .howcardwrapper{
        line-height: 1.4;
    }
    .takeAssesment{
        font-size: 14px;
    }
}
.ca102{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.ff33{
    position: relative;
    bottom: 5rem;
}
@font-face {
  font-family: "Brfirma-Regular";
  src: local("Brfirma-Regular"),
    url("./Brfirma/BRFirma-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Brfirma-Bold";
  src: local("Brfirma-Bold"),
    url("./Brfirma/BRFirma-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Brfirma-BoldItalic";
  src: local("Brfirma-BoldItalic"),
    url("./Brfirma/BRFirma-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Brfirma-ExtraLight";
  src: local("Brfirma-ExtraLight"),
    url("./Brfirma/BRFirma-ExtraLight.ttf") format("truetype");
}

html{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Brfirma-Regular' !important;
  color: #150538;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  background-color: #ffffff !important;
  background: #ffffff;
}
@media(max-width:700px){
  body{
    font-size: 14px;
    overflow-x: hidden;
  }
}
body{
  scroll-behavior: smooth;
  letter-spacing: normal;
}
.container-fluid{
  overflow-x: hidden;
}

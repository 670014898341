.smallmodal {
  width: 500px;
  margin-left: 20%;
}
.modlist {
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  font-weight: 600;
  justify-content: space-between;
}
.labelicon {
  width: 10px;
  margin-right: 5px;
}
.dev {
  margin-bottom: 1rem;
  margin-right: 1rem;
  color: #3751ff;
}
.intern {
  margin-right: 1rem;
  color: #ffbc41;
  margin-bottom: 1rem;
}
.interviewed {
  margin-right: 1rem;
  color: #f55f44;
  margin-bottom: 1rem;
}
.deletelabel {
  padding-left: 0.5rem;
}
.addit {
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.point {
  width: 6px;
  margin-left: 20px;
}
.slctlab {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.instruc {
  font-size: 10px;
  color: #bcbcbc;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin-top: 0.2rem;
  margin-bottom: 1.5rem;
}
.slctform {
  font-size: 12px;
  font-weight: 500;
}
.boxes {
  margin-bottom: 0.5rem;
}
.dsx {
  display: flex;
  align-items: center;
}
.cnew {
  color: #a4a6b3;
  margin-left: 0.7rem;
  outline: none;
  background: #f4f4f4;
  width: 100%;
  padding: 0.6rem 1rem;
  margin: 0px;
  margin-top: 0.7rem;
  border-radius: 8px;
}
.boxes label {
  margin-left: 0.8rem;
}
.fsave {
  float: right;
}
.piechrrt {
  min-width: 256px;
  width: 315px;
}
.smallscreenonly {
  display: none;
}

@media (max-width: 400px) {
  .smallmodal {
    width: 100%;
    margin-left: 0;
  }
  .next_page {
    padding: 0.5rem 1rem 1rem 0.8rem;
  }

  .adminbtn {
    flex: 40% 1;
    margin-top: 4.8rem;
  }
}

.slctlabaa {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.profl {
  text-align: center;
}
.txff {
  width: 150px;
  text-align: left;
}

.mail112 {
  width: 16px;
  margin: auto;
}
.clss1 {
  display: flex;
  justify-content: center;
}

.fsave:hover {
  background: #f2461b;
}
.preloader1 {
  position: absolute;
  top: 22%;
  z-index: -1;
  left: 89%;
  transform: translate(-50%, -50%);
}

.preloader2 {
  position: absolute;
  top: 25%;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader {
  width: 90%;
}
.addnw1 {
  color: #3965ff;
  text-align: right;
  cursor: pointer;
  margin-bottom: 1rem;
}

@media (max-width: 450px) and (min-width: 401px) {
  .preloader1 {
    display: none;
  }
  .preloader2 {
    top: 15%;
  }
}
.cnew::placeholder {
  font-size: 12px;
}
.deletelabel {
  cursor: pointer;
}
.cooming {
  width: 50%;
}
.frtr2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0px;
  color: #001833 !important;
  padding-bottom: 0px;
}
.ftre {
  color: #001833 !important;
  padding-bottom: 0px;
  font-weight: normal;
}
@media (max-width: 400px) {
  .piechrrt {
    width: 285px;
    width: 265px;
    margin: auto;
    margin-bottom: 1.4rem;
  }
  .closeselc2b {
    left: 1rem !important;
  }
  .erritmodal .modal-dialog {
    width: 95%;
  }
  .erritmodal .modal-dialog .modal-content {
    height: 129%;
    overflow-y: scroll;
  }
  .addz {
    overflow-y: scroll;
    height: 100%;
  }
}
.messageerit {
  font-size: 14px;
  width: 100%;
}
.worklife {
  width: 100%;
}
.bokdness {
  width: 50% !important;
  margin: auto;
}
.booknees {
  text-align: center;
  line-height: 28px;
  text-align: center;
  color: #666666;
  font-size: 14px;
}
.winebtn,
.winebtn:hover {
  color: white;
  background: #f2461b;
  border-radius: 8px;
  border: none;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 160px;
  font-size: 14px;
  outline: none;
  padding: 0.6rem;
}
.winebtn1,
.winebtn1:hover,
.winebtn1:focus {
  color: white;
  background: #f2461b;
  border-radius: 8px;
  border: none;
  width: 100px;
  font-size: 14px;
  outline: none;
  padding: 0.7rem;
}
.crrr1 {
  margin-top: 2rem;
  display: flex;
  background: #ececec;
  border-radius: 8px 8px 0px 0px;
  color: #999999;
  font-size: 14px;
  height: 77px;
  align-items: center;
  justify-content: center;
  padding-left: 3rem;
  width: 100%;
}
.crrr2 {
  margin-top: 0.1rem;
  border-radius: 0px;
}
.crgray {
  background: #f8f8f8;
}
.crrr1a {
  flex: 1;
}

.crrr1b {
  flex: 1;
}
.crrr1c {
  flex: 1;
}
.crrr1d {
  flex: 1;
}
.updownarrow {
  width: 9px;
  object-fit: contain;
  margin-left: 0.3rem;
}
.widdjt {
  width: 97%;
  margin-top: 1rem;
}
.wraptanll {
  box-shadow: 0px 12px 16px rgba(55, 48, 81, 0.08);
  border-radius: 8px;
  width: 97%;
}
.erritmodal .modal-dialog {
  width: 100%;
  max-width: 900px;
}
.modalnod {
  font-size: 18px;
  line-height: 37px;
  letter-spacing: 1px;
  color: #001833;
  font-weight: 700;
}
.mauris {
  background: #fcfcfc;
  border-radius: 8px;
  font-size: 14px;
  color: #999999;
  padding: 3rem 1rem;
  margin-top: 0rem;
}

.Uploads1 {
  color: #001833;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  font-size: 14px;
}
.fix3images {
  display: flex;
  /* flex-wrap:wrap; */
  overflow-y: hidden;
}
/* .fileContainer p{
  display: none;
} */
.professional4 {
  width: fit-content;
  min-width: 200px;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  max-width: 252px;
}
.modal-backdrop.show {
  opacity: 0.8 !important;
}
.tht12 {
  text-align: right;
}
.downloadicon {
  position: relative;
  bottom: 1.66rem;
  width: 20px;
  right: 1.5rem;
}

.eritimg1 {
  width: 34%;
}
@media (max-width: 780px) {
  .spna122 {
    bottom: 2.45rem;
  }
  .closeselc2b {
    cursor: pointer;
    position: relative;
    bottom: 1rem !important;
    left: 0rem !important;
    color: black;
  }
  .ssarh{
    top: 1.7rem;
  }
  .fmca1,
  .fmca1:focus,
  .fmca1:active,
  .fmca1 .form-control {
    overflow: scroll;
    overflow-y: scroll;
    height: 146px !important;
    padding-right: 0rem !important;
  }
  .profl {
    /* padding-left: 0px;
    padding-right: 0px; */
  }
}

.spna12 {
  text-align: right;
}
.spna122 {
  position: relative;
  bottom: 2.57rem;
  right: 0rem;
  background: #dddddd;
  border-radius: 8px 0px 0px 8px;
  color: #666666;
  padding: 1.05rem 1rem;
  width: 90px;
}
.spna122a {
  position: relative;
  bottom: 2.57rem;
  right: 0rem;
  background: #cbe2ff;
  border-radius: 0px 8px 8px 0px;
  color: #666666;
  padding: 1.05rem 1rem;
  cursor: pointer;
}
.fmca,
.fmca:focus,
.fmca:active,
.fmca .form-control {
  height: 57px !important;
  padding: 0rem 1rem;
  border-radius: 8px;
  background: #fcfcfc;
  padding-right: 1.5rem;
  font-size: 14px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-left: 5.9rem;
}
.eachfield {
  padding-right: 1rem;
  padding-left: 2rem;
}
.eachfield2 {
  padding-right: 1rem;
  padding-left: 2rem;
}
.spna1a2,
.textad {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 1rem;
}
.polx {
  padding-top: 2rem;
}
.fgrnbga {
  text-align: right;
  width: 98%;
}
.fgreen,
.fgreen:hover,
.fgreen:focus,
.fgreen:active {
  background: #cfffd2;
  border-radius: 8px;
  color: #1bb978;
  border: none;
  width: 100px;
  font-size: 14px;
}
.redd,
.redd:hover,
.redd:focus,
.redd:active {
  background: #ffe2dd;
  border-radius: 8px;
  color: #ff0000;
  border: none;
  width: 100px;
  font-size: 14px;
  position: relative;
  bottom: 2rem;
}

.bottombtn1 {
  padding-bottom: 0.5rem;
}
.linkert {
  color: #3965ff;
}

.coppd {
  position: absolute;
  color: #1bb978;
  font-weight: 700;
}
.bggray12 {
  background: #fcfcfc;
}
.dmdar {
  margin: 3rem;
  background: #ffffff;
  box-shadow: 0px 12px 16px rgba(69, 73, 88, 0.16);
  border-radius: 8px;
  padding: 3rem;
}
.fmc12,
.fmc12:hover,
.fmc12:focus,
.fmc12:active {
  box-sizing: border-box;
  border-radius: 8px;
  height: 55px !important;
  padding: 0rem 1rem;
  border-radius: 8px;
  background: #ffffff !important;
  font-size: 14px !important;
  color: #999999 !important;
  outline: none !important;
  border: none !important;
  border: 1px solid #dddddd !important;
}

.userprofile12 {
  font-size: 12px;
  background: #f9f9f9;
  position: relative;
  top: 0.7rem;
  left: 1.2rem;
  padding: 0.4rem 0.9rem;
  color: #333333;
}

.area22,
.area22:hover,
.area22:focus,
.area22:active {
  height: 120px !important;
  width: 100%;
  padding-top: 1rem;
}
.downa {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.arrowa {
  border: solid #3965ff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.jdd1 {
  margin-top: 0.7rem;
}
.addz {
  display: flex;
  flex-wrap: wrap;
}
.addz23 {
  background: #b3c1f5;
  color: #3751ff;
  padding: 0.2rem 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
}
.flf {
  background: #f1f1f1 !important;
  width: 140px !important;
  text-align: center;
}
.fileContainer .chooseFileButton {
  background: #dddddd !important;
  color: #666666 !important;
  border-radius: 8px !important;
}
.closeselc {
  padding-left: 0.5rem;
  cursor: pointer;
}
.odd132 {
  padding-top: 2rem;
}
.dropdownwrap {
  width: 320px;
  font-size: 14px;
  color: #666666;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(25, 32, 56, 0.24);
  border-radius: 8px;
  padding-bottom: 1rem;
  padding-top: 0.4rem;
  position: absolute;
  z-index: 3;
}
.relddro {
  position: relative;
  top: 4rem;
  left: 10rem;
}
.chckcontainer {
  display: block;
  position: relative;
  padding-left: 12px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 24px;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 1.54rem;
  top: 0.1rem;
}

/* Hide the browser's default checkbox */
.chckcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.chckcheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 8px;
}

/* On mouse-over, add a grey background color */
.chckcontainer:hover input ~ .chckcheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chckcontainer input:checked ~ .chckcheckmark {
  background-color: #46d68c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.chckcheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chckcontainer input:checked ~ .chckcheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chckcontainer .chckcheckmark:after {
  left: 8px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ddrpp {
  padding: 0.5rem;
  padding-left: 2rem;
  font-weight: 600;
}
.drrpr2 {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
.tittle1 {
  font-weight: 500;
  padding: 1rem;
  padding-bottom: 0.2rem;
  font-size: 13px;
  padding-left: 1.5rem;
}
.divline12 {
  border-top: 0.1px solid #c9c7c78c;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 0.7rem;
}

@media (max-width: 600px) {
  .relddro {
    left: 0rem;
  }
  .dmdar {
    margin: 0rem;
    padding: 1rem;
  }
  .fjss {
    line-height: 0.6;
    padding-bottom: 1rem;
  }
  .messageerit {
    padding-bottom: 2rem;
  }
  .addz {
    height: auto;
    display: inline-block;
  }
  .Uploads1{
    padding-left: 0px;
  }
  .eachfield2,.eachfield{
    padding-left: 1rem;
  }
}
@media (max-width: 350px) {
  .dropdownwrap {
    width: 299px;
  }
  .booknees {
    font-size: 12px;
  }
  .kisls {
    padding-top: 1rem;
  }
}
.popUUp {
  width: 100px;
}
.areusure1 {
  text-align: center;
  padding: 2rem 1rem;
}
.planupgrade {
  display: flex;
}
.mddd1,
.mddd1:hover {
  background: none;
  color: #46d68c;
  border: 1px solid #46d68c;
}
.mddd12,
.mddd12:hover {
  background: #3965ff;
  color: #ffff;
  border: #3965ff;
}
.dggh {
  position: relative;
  bottom: 1.38rem;
}

@media (max-width: 600px) {
  .smallmodal {
    width: 365px;
    margin-left: 10%;
  }
  .crrr1a,
  .crrr1b,
  .crrr1c,
  .crrr1d {
    min-width: 100px;
  }
  .crrr1 {
    padding-left: 0.8rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    padding-top: 1rem;
    height: 110px;
  }
  .smallscreenonly {
    display: block;
    color: #666666;
    font-size: 12px;
    width: 110px;
    min-width: 110px;
    padding-bottom: 0.6rem;
  }
  .winebtn1,
  .winebtn1:hover,
  .winebtn1:focus {
    padding: 0.2rem;
  }
  .crrr1a,
  .crrr1b,
  .crrr1c,
  .crrr1d {
    padding-bottom: 0.5rem;
  }
  .kisls {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  .hideinsmall {
    display: none;
  }
  .crrr1d {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 380px) {
  .crrr1 {
    height: 164px;
  }
}

.clar23 {
  color: #f2461b !important;
  cursor: pointer;
}
.closeselc2a {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  font-size: 1.7rem;
}
.closeselc2b {
  cursor: pointer;
  position: relative;
  bottom: 3rem;
  left: 2rem;
}
@media (max-width: 480px) {
  .closeselc2b {
    left: 1rem !important;
  }
  .erritmodal .modal-dialog {
    width: 95% !important;
  }
  .fgreen {
    bottom: 5rem;
    left: 1rem;
  }
}
.linkert {
  cursor: pointer;
}
.linkertq {
  padding-left: 9rem;
  color: initial;
}
.clss1a {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.clss1aa1,
.clss1aa {
  width: 150px;
  margin-right: 0.5rem;
}
.ddsfl {
  padding-left: 1rem;
}
.ddsfl .Uploads1 {
  color: #666666;
  font-size: 13px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0.5rem;
}
.addzxx {
  color: #fff !important;
  background: #82be9f !important;
}
.centrmargin {
  margin: auto;
}
.redd,
.redd:hover,
.redd:focus,
.redd:active {
  bottom: 0rem;
  margin-left: 0.4rem;
}
.mop12 {
  text-align: left;
  font-weight: 600;
  padding-bottom: 0.6rem;
}
.xxs{
  color: #fff;
  position: relative;
  left: .8rem;
  font-weight: 900;
  font-size: 12px;
  cursor: pointer;
}
.ssarh{
  width: 13px;
  position: relative;
  top: 2.3rem;
  left: 0.5rem;
}
.addnew{
  color: #1bb978;
  cursor: pointer;
}
.close23{
  color: #ff0000;
  padding-left: 1rem;
  cursor: pointer;
  font-weight: bold;
}
.flexxend{
  display: flex;
  flex-direction: column;
}
.categoryaa{
  background: #f5f5f5;
  width: fit-content;
  min-width: 250px;
  text-align: center;
  margin-top: .6rem;
  border-radius: .4rem;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 1rem;
  color: #666666;
}
.fmcaaa, .fmcaaa:focus, .fmcaaa:active, .fmcaaa .form-control {
  height: 57px !important;
  padding: 0rem 1rem;
  border-radius: 8px;
  background: inherit;
  padding-right: 1.5rem;
  font-size: 14px;
  border: none !important;
  outline: none !important;
  margin-right: .4rem;
  color: inherit;
  box-shadow: none !important;
  max-width: 120px;
}
.crrr1cc{
  width: 130px;
}

.ddd4{
  margin-top: 2rem;
}
.createcategory{
  font-size: 12px;
}
.dteamheading{
  width: 88% !important;
}
.dfname{
  flex: 24% 1;
}
.amoreopt {
  flex: 10% 1;
  max-width: 71px;
}
.closeicone{
  cursor: pointer;
}
a.linksss {
  color: #0f1a29;
}
a.linksss:visited {
  color: #0f1a29;
}
a.linksss:active {
  color: #0f1a29;
}
.jsmc {
  display: flex;
  padding-top: 3rem;
  justify-content: space-between;
  margin-top: 4rem;
  flex-wrap: wrap;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.006);
}
.nysc {
  width: 100px;
}
@media (min-width: 766px) {
  .jsmc {
    display: flex;
    justify-content: space-between;
    padding-right: 3rem;
  }
  .textleft {
    text-align: left;
  }
}
.cclas {
  width: 35px;
}
.yudimynysc {
  width: 100%;
}
.powerdby {
  padding-top: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.style11 {
  display: inline-block;
  padding: 0.2rem;
}
.yudimynysc1 {
  width: 140px;
}
.ncb {
  display: flex;
  flex-direction: column;
  padding-left: 2.4rem;
  justify-content: center;
}
.yudims {
  margin-left: 0rem;
  padding-left: 0px;
  margin-top: 1rem;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  color: #150538;
  line-height: 1.8;
  word-spacing: 1.8px;
}
.style1112{
  padding-left: .5rem;
}
.poweredbytxt {
  font-size: 18px;
  font-family: 'Brfirma-Bold';
  color: #001833;
}
.eritydyfooter {
  /* position: absolute;
  bottom: 0; */
  display: flex;
  justify-content: flex-end;
  /* right: 0; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media(max-width: 600px) {
  .powerdby {
    justify-content: center;
    width: 100vw;
  }
}

@media(max-width: 798px) {
  .flexsss .saedlogo {
    width: 35px;
  }
}